import * as R from 'ramda';
import Backbone from 'backbone';
import AppChannel from 'modules/channels/App';
import { send } from 'modules/http';
import { impersonate, authenticate, jsonRequest, prefixUrl } from 'modules/http/request';
import { useRefreshToken, getToken } from './authentication';
import { clearUserAndToken } from 'models/users.js';
import { captureException } from 'modules/error-reporting';

const API_ROOT = process.env.API_ROOT;

function redirectToLogin() {
    clearUserAndToken();
    Backbone.history.navigate('/login', {
        trigger: true,
        replace: true,
    });
}

function authenticateAndRetry(request) {
    return useRefreshToken()
        .then(({ access_token }) => send(authenticate(access_token, request)))
        .catch(err => {
            if (err.message === 'refresh_token_invalid') {
                return redirectToLogin();
            }
            throw err;
        });
}

function showMaintenancePage(request, error) {
    AppChannel.vent.trigger('http:error', error.status);
}

function showErrorPage(request, error) {
    AppChannel.vent.trigger('http:error', error.status);
    throw error;
}

function handleServerError(request) {
    return function(error) {
        if (error.status === 401) {
            return authenticateAndRetry(request);
        } else if (error.status === 503) {
            return showMaintenancePage(request, error);
        } else if (error.status >= 500) {
            return showErrorPage(request, error);
        }
        throw error;
    };
}

function createMethod(method) {
    return function(url, data, headers, isPublic = false) {
        if (url.includes('undefined') || url.includes('null')) {
            const error = new Error('URL contains "undefined" or "null"');
            captureException(error, {
                extra: {
                    method,
                    url,
                    data,
                    headers,
                    isPublic,
                },
            });
        }

        const request = R.compose(
            isPublic ? R.identity : authenticate(getToken('access_token')),
            isPublic ? R.identity : impersonate(getToken('impersonate')),
            prefixUrl(API_ROOT),
            jsonRequest
        )({
            method,
            url,
            data: method === 'GET' ? data : JSON.stringify(data),
            headers,
        });
        return send(request).catch(handleServerError(request));
    };
}

export const del = createMethod('DELETE');
export const get = createMethod('GET');
export const post = createMethod('POST');
export const put = createMethod('PUT');
export const patch = createMethod('PATCH');

function dataURItoBlob(dataURI) {
    const [meta, data] = dataURI.split(',');

    const mimeString = meta.split(':')[1].split(';')[0];

    const byteString = atob(data);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

export const uploadDataUrl = (url, dataUrl, additionalFormData = {}, fileName) => {
    const formData = new FormData();
    if (fileName) {
        formData.append('file', dataURItoBlob(dataUrl), fileName);
    } else {
        formData.append('file', dataURItoBlob(dataUrl));
    }
    R.forEachObjIndexed((value, key) => {
        formData.append(key, value);
    }, additionalFormData);

    const request = R.pipe(
        authenticate(getToken('access_token')),
        impersonate(getToken('impersonate')),
        prefixUrl(API_ROOT)
    )({
        url,
        method: 'POST',
        cache: false,
        contentType: false,
        processData: false,
        headers: {},
        data: formData,
    });

    return send(request).catch(handleServerError(request));
};

function readFileAsDataUrl(file) {
    return new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onload = function() {
            resolve(fileReader.result);
        };
        fileReader.readAsDataURL(file);
    });
}

export function uploadFile(url, file, additionalFormData) {
    return readFileAsDataUrl(file).then(fileDataUrl =>
        uploadDataUrl(url, fileDataUrl, additionalFormData, file.name)
    );
}
