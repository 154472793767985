import _ from 'underscore';
import Globalize from 'globalize';
import URI from 'URIjs';
import Backbone from 'backbone';
import geodist from 'geodist';
import { currencies } from 'modules/config/currencies';
import { getLocalizedTime, getLocalizedDay, getLocalizedDate } from 'modules/utils/dateAndTime';
import { filerPhoto } from './filer';
import { getActiveLanguage } from 'models/locales.js';

const Utils = {};

import 'URIjs/src/URITemplate';

const nbsp = String.fromCharCode(160);

const units = {
    mg: {
        type: 'weight',
        value: 1,
        bigger: 'g',
    },
    g: {
        type: 'weight',
        value: 1000,
        bigger: 'kg',
    },
    kg: {
        type: 'weight',
        value: 1000000,
    },
    mL: {
        type: 'volume',
        value: 1,
        bigger: 'L',
    },
    cL: {
        type: 'volume',
        value: 10,
    },
    L: {
        type: 'volume',
        value: 1000,
    },
    u: {
        type: 'unit',
        value: 1,
    },
};

export const quantityUnitPropTypes = ['mg', 'mL'];
export const quantityStrategyPropTypes = ['unit', 'weight', 'both'];

Utils.expandURL = function(tpl, options) {
    return URI.expand(tpl, options).toString();
};

Utils.getTimeInSeconds = function() {
    // jshint bitwise: false
    return (Date.now() / 1000) | 0;
    // jshint bitwise: true
};

Utils.getDefaultQuantityUnit = function(unit) {
    const unitType = units[unit] && units[unit].type;
    switch (unitType) {
        case 'weight':
            return 'kg';
        case 'volume':
            return 'L';
        default:
            return null;
    }
};

// displayUnit is a string
Utils.quantityString = function(quantity, displayUnit) {
    let convertedAmount;
    let resultUnit;

    resultUnit = displayUnit || quantity.unit;

    if (resultUnit === 'default') {
        resultUnit = Utils.getDefaultQuantityUnit(quantity.unit);
    }

    convertedAmount =
        quantity.amount === 0 ? 0 : Utils.unitConverter(quantity.amount, quantity.unit, resultUnit);

    // Force bigger unit when quantity is above 1000
    while (convertedAmount >= 1000 && units[resultUnit].bigger) {
        resultUnit = units[resultUnit].bigger;
        convertedAmount = Utils.unitConverter(quantity.amount, quantity.unit, resultUnit);
    }
    convertedAmount = Utils.getLocalizedNumber(convertedAmount, 4);

    return convertedAmount && `${convertedAmount} ${resultUnit}`;
};

Utils.getQuantityUnitType = function(unit) {
    return units[unit] && units[unit].type;
};

Utils.unitConverter = function(amount, originalUnit, newUnit) {
    if (
        typeof amount === 'undefined' ||
        amount === null ||
        !originalUnit ||
        !newUnit ||
        units[originalUnit].type !== units[newUnit].type
    ) {
        return null;
    }

    // Globalize.parseFloat expects a string and breaks a Number is sent
    const sanitizedAmount = Globalize.parseFloat(`${amount}`);

    if (!Utils.isPositiveFloat(sanitizedAmount)) {
        return null;
    }

    return sanitizedAmount * (units[originalUnit].value / units[newUnit].value);
};

Utils.getCurrencySymbol = function getCurrencySymbol(currency) {
    return currencies[currency] && currencies[currency].symbol;
};

Utils.pricePerQuantity = function(money, quantity) {
    if (quantity.amount === 0) {
        return '-';
    }

    const displayUnit = Utils.getDefaultQuantityUnit(quantity.unit);
    const convertedAmount = Utils.unitConverter(quantity.amount, quantity.unit, displayUnit);
    const finalAmount = _.isFinite(convertedAmount) ? convertedAmount : quantity.amount;
    const finalUnit = _.isFinite(convertedAmount) ? displayUnit : quantity.unit;

    return `${Utils.getPrice(money, finalAmount)}/${finalUnit}`;
};

/*
    money: a money VO containing amount and currency properties
    amount: an optional amount (defaults to 1) allowing the final price to be divided by this amount
*/
Utils.getPrice = function(money, amount) {
    if (!money) {
        return null;
    }

    const sanitizedAmount = Utils.isPositiveFloat(amount) ? amount : 1;
    const price = money.amount / 100 / sanitizedAmount;
    const localizedPrice = Utils.getLocalizedNumber(price, 2, true);
    const currency = currencies[money.currency];

    if (!currency) {
        return null;
    }

    if (currency.symbolPosition === 'before') {
        return `${currency.symbol + localizedPrice} `;
    }

    return localizedPrice + String.fromCharCode(160) + currency.symbol;
};

Utils.getLocalizedDay = function(day) {
    return getLocalizedDay(window.language, day);
};

// See all possible date formats here : https://github.com/jquery/globalize/tree/79ae658b842f75f58199d6e9074e01f7ce207468#dates
// We added an additional Intermediate Date 'I' format, which is 'D' without the year
Utils.getLocalizedDate = function(date, format) {
    return getLocalizedDate(window.language, format, date);
};

Utils.getLocalizedTime = function(time) {
    return getLocalizedTime(window.language, time);
};

/**
 * Format a number according to locale.
 * @param  {float}  num                the number
 * @param  {number}    decimals           number of decimals to include
 * @param  {boolean}   alwaysShowDecimals show decimals event if the final number is a round number
 * @return {string} the formatted number
 */

Utils.getLocalizedNumber = function(num, decimals, alwaysShowDecimals) {
    if (alwaysShowDecimals !== true && num % 1 === 0) {
        return num;
    }

    const decimalsOrDefault = decimals || 2;
    let localizedNum = Globalize.format(num, `n${decimalsOrDefault}`);

    if (alwaysShowDecimals !== true && typeof localizedNum === 'string') {
        localizedNum = localizedNum.replace(/[,.]?0+$/, '');
    }

    return localizedNum;
};

// Warning : that method can return true even if there's a string in input
Utils.isPositiveFloat = function(num, allowZero) {
    const sanitizedNum = parseFloat(num);
    return (
        _.isNumber(sanitizedNum) &&
        !_.isNaN(sanitizedNum) &&
        ((allowZero === true && sanitizedNum >= 0) || (allowZero !== true && sanitizedNum > 0))
    );
};

Utils.getAbsoluteUrl = function(fragment) {
    const fragmentOrDefault = fragment || Backbone.history.getFragment();
    return `${location.protocol}//${
        location.port ? `${location.hostname}:${location.port}` : location.hostname
    }${Backbone.history.root}${fragmentOrDefault}`;
};

Utils.linkTo = function(link, options) {
    let expandedLink;

    if (options && !_.isEmpty(options.hash)) {
        expandedLink = Utils.expandURL(link, options.hash);
    } else {
        expandedLink = link;
    }

    if (___SERVER___) {
        console.warn('Not isomorphic yet.');
        return link;
    }

    if (Backbone.history.root) {
        return Backbone.history.root + expandedLink;
    }
    return expandedLink;
};

Utils.filerLinkTo = function(link, options) {
    let expandedLink;

    if (!link) {
        return false;
    }

    if (options && !_.isEmpty(options.hash)) {
        expandedLink = Utils.expandURL(link, options.hash);
    } else {
        expandedLink = link;
    }

    return process.env.FILER_ROOT + expandedLink;
};

Utils.filerPhoto = filerPhoto;

Utils.apiLinkTo = function(link, options) {
    let expandedLink;

    if (!link) {
        return false;
    }

    if (options && !_.isEmpty(options.hash)) {
        expandedLink = Utils.expandURL(link, options.hash);
    } else {
        expandedLink = link;
    }

    return `${process.env.API_ROOT}/${expandedLink}`;
};

Utils.redirectTo = url => {
    window.location = url;
};

if (!Date.now) {
    Date.now = function now() {
        return new Date().getTime();
    };
}

Utils.localizeDistance = function(distanceInMeters, countryCode, transChoice, noAbbreviation) {
    const useMiles = countryCode === 'GB';
    const distInKm = parseFloat(distanceInMeters / 1000, 10);
    const kmsToMiles = 0.621371;

    if (useMiles) {
        const distInMiles = (distInKm * kmsToMiles).toFixed();
        return distInMiles > 1 ? `${distInMiles + nbsp}miles` : `${distInMiles + nbsp}mile`;
    }

    if (noAbbreviation) {
        return `${distInKm.toFixed() +
            nbsp +
            transChoice('payment.successful.order.kilometer', distInKm.toFixed())}`;
    }

    return `${distInKm.toFixed() + nbsp}km`;
};

Utils.getLocalizedDistance = function(from, to, countryCode) {
    const normalizedFrom = {
        lat: from.lat || from.latitude,
        lon: from.lon || from.lng || from.longitude,
    };
    const normalizedTo = {
        lat: to.lat || to.latitude,
        lon: to.lon || to.lng || to.longitude,
    };
    const distance = geodist(normalizedFrom, normalizedTo, { unit: 'meters' });
    return Utils.localizeDistance(distance, countryCode);
};

Utils.getAssemblyTitle = function(assemblyNameOrObject, showName) {
    let assemblyTitle;
    let assembly;

    if (!assemblyNameOrObject) {
        return '';
    }

    if (_.isString(assemblyNameOrObject)) {
        assemblyTitle = assemblyNameOrObject;
    } else {
        assembly = assemblyNameOrObject.get ? assemblyNameOrObject.toJSON() : assemblyNameOrObject;
        assemblyTitle = assembly.place.address.city.name;
        if (showName) {
            assemblyTitle += ` - ${assembly.name}`;
        }
    }

    const activeLanguage = getActiveLanguage();
    const locale = activeLanguage.code;

    if (['fr-FR'].indexOf(locale) > -1) {
        assemblyTitle = `Ruche ${assemblyTitle}`;
    }

    return assemblyTitle;
};

export const apiLinkTo = Utils.apiLinkTo;
export const expandURL = Utils.expandURL;
export const filerLinkTo = Utils.filerLinkTo;
export const getAbsoluteUrl = Utils.getAbsoluteUrl;
export const getAssemblyTitle = Utils.getAssemblyTitle;
export const getCurrencySymbol = Utils.getCurrencySymbol;
export const getDefaultQuantityUnit = Utils.getDefaultQuantityUnit;
export const getLocalizedDistance = Utils.getLocalizedDistance;
export const getLocalizedNumber = Utils.getLocalizedNumber;
export const getPrice = Utils.getPrice;
export const getQuantityUnitType = Utils.getQuantityUnitType;
export const getTimeInSeconds = Utils.getTimeInSeconds;
export const isPositiveFloat = Utils.isPositiveFloat;
export const linkTo = Utils.linkTo;
export const localizeDistance = Utils.localizeDistance;
export const pricePerQuantity = Utils.pricePerQuantity;
export const quantityString = Utils.quantityString;
export const redirectTo = Utils.redirectTo;
export const unitConverter = Utils.unitConverter;

export default Utils;
