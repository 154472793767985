import * as R from 'ramda';
import { get, put, post, patch, del } from 'modules/api';

/**
 * Get hive by id.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @returns {Promise<Object>}
 *
 */
export const fetchHive = assemblyId => get(`hives/${assemblyId}`);

/**
 * Get all hives.
 *
 * @async
 * @function
 * @returns {Promise<Object[]>}
 *
 */
export const fetchHives = () => get('hives/?summary=true&delivery=true').then(R.prop('hives'));

/**
 * Get the finalized distributions follow up for host.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @returns {Promise<Object[]>}
 *
 */
export const fetchInvoicesArchives = assemblyId =>
    get(`hives/${assemblyId}/distributions/archive/v2`);

/**
 * Get producers highlighted by the hive.
 *
 * @async
 * @function
 * @param {number\string} assemblyId (id|uuid)
 * @returns {Promise<Object[]>}
 *
 */
export const getHighlightedProducers = assemblyId => get(`hives/${assemblyId}/highlight-clusters`);

/**
 * Get all farms by hive.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @returns {Promise<Object[]>}
 *
 */
export const fetchFarms = assemblyId => get(`hives/${assemblyId}/farms`);

/**
 * Get all farms images.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @param {number} limit
 * @returns {Promise<Object[]>}
 *
 */
export const fetchFarmsGallery = (assemblyId, limit) =>
    get(`hives/${assemblyId}/farms_gallery`, { limit }, {}, true);

/**
 * Adds a user as a member of a hive.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @param {number} userId (id|uuid)
 * @returns {Promise<Object>}
 *
 */
export const userJoinAssembly = (assemblyId, userId) =>
    put(`hives/${assemblyId}/members/${userId}`);

/**
 * Get current clusters.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @returns {Promise<Object[]>}
 *
 */
export const fetchHighlightClustersArchives = assemblyId =>
    get(`hives/${assemblyId}/highlight-clusters/archives`);

/**
 * Get financial documents.
 * @param {numbe|string} assemblyId (id|uuid)
 * @returns {Promise<Object[]>}
 */
export const fetchFinancialDocuments = assemblyId => get(`hives/${assemblyId}/financialDocuments`);

/**
 * Get hives near their farm
 * @param {number} farmId
 * @returns {Promise<Object[]>}
 */
export const fetchHivesNearFarm = farmId => get(`hives/?farm=${farmId}`);

/**
 * Archive and create a new place.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @returns {Promise<Object>}
 *
 */
export const resetPlace = assemblyId =>
    post(`hives/${assemblyId}/reset_place`, {
        extraDeliveryTimeSlot: {},
        name: null,
    });

/**
 * Launch the pre-construction of a hive.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @returns {Promise<Object>}
 *
 */
export const launchPreconstruction = assemblyId => put(`hives/${assemblyId}/pre_construct`);

/**
 * Launch the construction of a hive.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @returns {Promise<Object>}
 *
 */
export const launchConstruction = assemblyId => put(`hives/${assemblyId}/construct`);

/**
 * Edit a companyId.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @param {number|string} companyId (id|uuid)
 * @returns {Promise<Object>}
 *
 */
export const setCompanyId = ({ assemblyId, companyId }) =>
    put(`hives/${assemblyId}/companies/${companyId}`);

/**
 * Edit a companyId.
 *
 * @async
 * @function
 * @param {number|string} assemblyId (id|uuid)
 * @param {Object[]} datas
 * @returns {Promise<Object>}
 *
 */
export const editHive = (assemblyId, datas) => patch(`hives/${assemblyId}`, datas);

/**
 * Edit shipping address
 *
 * @param {number|string} assemblyId (id|uuid)
 * @param {Object} datas
 * @returns {Promise}
 */
export const editShippingAddress = (assemblyId, datas) =>
    patch(`hives/${assemblyId}/shipping_address`, datas);

/**
 * Get farms with proposals
 *
 * @param {number} assemblyId
 * @returns
 */
export const fetchFarmsWithProposals = assemblyId =>
    get(`hives/${assemblyId}/farms_with_proposals`);

/**
 * Get distributions statistics by hive.
 *
 * @param {number|string} assemblyId (id|uuid)
 * @returns {Promise<Object>}
 */
export const getHiveDistributionsStatistics = assemblyId =>
    get(`hives/${assemblyId}/distributions_statistics`);

/**
 * Get calandar of hive.
 *
 * @param {number|string} assemblyId (id|uuid)
 * @param {Object} options
 * @returns {Promise}
 */
export const getHiveCalendar = (assemblyId, options) => {
    return get(`hives/${assemblyId}/calendar`, options);
};

/**
 *
 *
 * @param {number|string} assemblyId (id|uuid)
 * @returns {Promise}
 */
export const fetchHiveComparisons = assemblyId => get(`hives/${assemblyId}/comparisons`);

/**
 *
 *
 * @param {number|string} assemblyId (id|uuid)
 * @param {number|string} farmId (id|uuid)
 * @returns {Promise}
 */
export const deleteProvidingFarmFromHive = (assemblyId, farmId) => {
    return del(`hives/${assemblyId}/farms/${farmId}`);
};

/**
 * Get farms near hive.
 *
 * @param {number|string} hiveId (id|uuid)
 * @param {Object} filters
 * @returns {Promise}
 */
export const fetchFarmsNearHive = (hiveId, filters) =>
    get(`hives/${hiveId}/search_farms`, { ...filters });

/**
 *
 *
 * @param {number|string} assemblyId
 * @returns {Promise}
 */
export const fetchHiveDashboard = assemblyId => get(`hives/${assemblyId}/dashboard`);

/**
 * Delete user who has member of hive.
 *
 * @param {number} userId
 * @param {number} assemblyId
 * @returns {Promise}
 */
export const deleteUserInHive = (userId, assemblyId) =>
    del(`hives/${assemblyId}/members/${userId}`);

/**
 * Get hive members
 *
 * @param {number|string} assemblyId
 * @param {number} _.page
 * @param {string} _.orderBy
 * @param {string} _.order
 * @returns {Promise}
 */
export const fetchHiveMembers = (assemblyId, { page, orderBy, order }) =>
    get(`hives/${assemblyId}/members`, {
        page,
        orderBy,
        order,
    });

/**
 * Flag member as favorite in a hive.
 * @param {number|string} assemblyId (id|uuid)
 * @param {number|string} memberId (id|uuid)
 * @returns {Promise}
 */
export const putFavouriteMemberOfHive = (assemblyId, memberId) =>
    del(`hives/${assemblyId}/favorites/${memberId}`);

/**
 * Remove member as favorite in a hive.
 * @param {number|string} assemblyId (id|uuid)
 * @param {number|string} memberId (id|uuid)
 * @returns {Promise}
 */
export const deleteFavouriteMemberOfHive = (assemblyId, memberId) =>
    put(`hives/${assemblyId}/favorites/${memberId}`);
